import Section from "../../../section/Section";
import StatsBox from "../../../v2/StatsBox";

export default function OnDemandSection() {
	return (
		<Section className="on-demand-section text-center pt-10 pt-md-10 pb-13 pb-md-19 border-bottom border-top border-dark">
			<p className="m-0 font-size-lg text-underline pb-3 pb-md-8">On-demand</p>
			<h2 className="mx-auto pb-5 pb-md-10" style={{ maxWidth: 386 }}>
				From roadblocks to roads ahead
			</h2>
			<div className="mx-auto" style={{ maxWidth: 477 }}>
				<p className="m-0 font-size-xl">
					We all lead busy lives; you can make progress at your own pace. Easily book and chat 1:1 with your coach in a
					video call or web chat when you’re in need of support.
				</p>
			</div>
		</Section>
	);
}
