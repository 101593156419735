import Section from "../../../section/Section";
import { useState } from "react";
import AskNimSVG from "@images/ask-nim.svg";
import Image from "../../../Image";

const plans = [
	{
		title: "Free",
		link: "https://app.staynimble.co.uk/register?publicInviteCode=nTb8HOV4Xs4",
		subTitle: "TRIAL THE PLATFORM",
		monthlyPrice: 0,
		yearlyPrice: 0,
		isBestValue: false,
		description: "Free access to the platform with 30 mins of Career Coaching.",
		part1: ["One-off 30 mins Coaching Credit", "-nim- Career Co-Pilot Access"],
		part2: [
			"My Skills Library — Builder",
			"My Skills Library — Editor",
			"Skills Match with Occupations",
			"Career Skills Gap Assessment",
			"Personalised Career Matches",
		],
		part3: [
			"Comprehensive Coaching Notes",
			"Bookmark Articles",
			"Local In-demand Careers data",
			"Over 800 Occupational Profiles",
			"Career Favouriting",
			"Learning Hub - 1000s of courses",
			"Achievement tracking",
		],
	},
	{
		title: "Lite",
		link: "https://app.staynimble.co.uk/register?publicInviteCode=nTb8HOV4Xs4",
		subTitle: "MOST AFFORDABLE",
		monthlyPrice: 5,
		yearlyPrice: 5,
		isBestValue: false,
		description:
			"Get our most affordable package and get access to all the Premium Articles, Videos and Podcasts on the platform.",
		part1: [
			"One-off 15 mins Coaching Credit",
			"-nim- Career Co-Pilot Access",
			"Premium Articles, Videos & Podcasts",
			"Recorded Webinars",
		],
		part2: [
			"My Skills Library — Builder",
			"My Skills Library — Editor",
			"Skills Match with Occupations",
			"Career Skills Gap Assessment",
			"Personalised Career Matches",
		],
		part3: [
			"Comprehensive Coaching Notes",
			"Bookmark Articles",
			"Local In-demand Careers data",
			"Over 800 Occupational Profiles",
			"Career Favouriting",
			"Learning Hub - 1000s of courses",
			"Achievement tracking",
		],
	},
	{
		title: "Premium",
		link: "https://app.staynimble.co.uk/register?publicInviteCode=nTb8HOV4Xs4",
		subTitle: "SAVE 20% ANNUALLY",
		monthlyPrice: 60,
		yearlyPrice: 48,
		isBestValue: true,
		description: "For those who need monthly check-ins with a dedicated qualifed coach.",
		part1: [
			"1 hr Coaching Credit/month",
			"-nim- Career Co-Pilot Access",
			"Top 5 CliftonStrengths Assessment",
			"In-app and Email Coaching Support",
			"Premium Articles, Videos & Podcasts",
			"Recorded Webinars",
		],
		part2: [
			"My Skills Library — Builder",
			"My Skills Library — Editor",
			"Skills Match with Occupations",
			"Career Skills Gap Assessment",
			"Personalised Career Matches",
		],
		part3: [
			"Comprehensive Coaching Notes",
			"Bookmark Articles",
			"Local In-demand Careers data",
			"Over 800 Occupational Profiles",
			"Career Favouriting",
			"Learning Hub - 1000s of courses",
			"Achievement tracking",
		],
	},
	{
		title: "Platinum",
		link: "https://app.staynimble.co.uk/register?publicInviteCode=nTb8HOV4Xs4",
		subTitle: "SAVE 20% ANNUALLY",
		monthlyPrice: 250,
		yearlyPrice: 200,
		isBestValue: false,
		description: "Access to your dedicated, qualified coach whenever you need it.",
		part1: [
			"Unlimited Coaching Credit",
			"-nim- Career Co-Pilot Access",
			"Top 5 CliftonStrengths Assessment",
			"In-app and Email Coaching Support",
			"Premium Articles, Videos & Podcasts",
			"Recorded Webinars",
		],
		part2: [
			"My Skills Library — Builder",
			"My Skills Library — Editor",
			"Skills Match with Occupations",
			"Career Skills Gap Assessment",
			"Personalised Career Matches",
		],
		part3: [
			"Comprehensive Coaching Notes",
			"Bookmark Articles",
			"Local In-demand Careers data",
			"Over 800 Occupational Profiles",
			"Career Favouriting",
			"Learning Hub - 1000s of courses",
			"Achievement tracking",
		],
	},
];

export default function IndividualPricingPart1() {
	const [isYearly, setIsYearly] = useState(false);
	return (
		<Section className="individual-pricing-part-1 pt-0 pt-md-14 mt-19 mt-md-20 mb-md-18 font-weight-medium">
			<div className="d-flex flex-column text-center">
				<p className="m-0 pb-5 pb-md-6 mx-auto font-size-lg text-underline">Personal Plans</p>
				<h2 className="h1 pb-8 pb-md-9">Join over 18,000 Members</h2>
				<p className="m-0 pb-9 pb-md-15 mx-auto font-weight-normal" style={{ maxWidth: 630 }}>
					Start with our free plan and upgrade as you grow in confidence. Access to best-in-class tools and learning
					content curated by our highly qualified coaches.
				</p>
				<div className="d-flex justify-content-center align-items-center pb-14 mb-0 mb-md-13 toggle-wrapper">
					<span className={`${isYearly ? "" : "text-underline"}`}>Pay Monthly</span>
					<label className="switch mx-4 mx-md-6">
						<input
							type="checkbox"
							checked={isYearly}
							onChange={(e) => {
								setIsYearly(e.target.checked);
							}}
						/>
						<span className="slider round"></span>
					</label>
					<span className={`${isYearly ? "text-underline" : ""}`}>Pay Annually</span>
				</div>
			</div>

			<div className="row equal plan-options">
				{plans.map((plan, index) => (
					<PlanOption key={index} {...plan} isYearly={isYearly} index={index} />
				))}
			</div>
		</Section>
	);
}

function PlanOption({
	title,
	subTitle,
	monthlyPrice,
	yearlyPrice,
	isBestValue,
	description,
	part1,
	part2,
	part3,
	isYearly,
	link,
	index,
}) {
	return (
		<div
			className={`plan-option col-12 col-md-6 col-lg-3 mb-6  pl-0 pr-0 ${index === 0 ? "pl-md-0 pr-md-4" : ""} ${
				index === 3 ? "pr-md-0 pl-md-4" : ""
			} ${index === 1 && "pl-md-2 pr-md-3"} ${index === 2 && "pl-md-3 pr-md-2"}`}
		>
			{isBestValue ? (
				<div className="mt-10 mt-md-0">
					<div
						className="pt-3 bg-light-green border-dark border-top border-left border-right text-center"
						style={{ height: 45 }}
					>
						Best Value
					</div>
				</div>
			) : (
				<div>
					<div style={{ height: 45 }}></div>
				</div>
			)}
			<div className="h-100 pt-5 border border-dark">
				<div className="pl-4 pr-2 pb-5 d-flex flex-column justify-content-between" style={{ height: 367 }}>
					<div>
						<div className="font-size-xl font-weight-bold pb-7">{title}</div>
						<div>
							<span style={{ fontSize: 50 }}>
								{monthlyPrice === 0 ? "Free" : `£${isYearly ? yearlyPrice : monthlyPrice}`}
							</span>
							<span className="font-weight-bold font-size-medium">{monthlyPrice !== 0 && " / month"}</span>
						</div>
						<div className="pt-0 pb-6 font-size-xxxs">{subTitle}</div>
						<div className="font-size-medium">{description}</div>
					</div>
					<a
						href={link}
						className="d-flex flex-column align-items-center justify-content-center btn-hover-light-green border border-dark mr-2 text-no-underline text-black"
						style={{ height: 54 }}
					>
						<span>{monthlyPrice !== 0 && "Free "}Sign Up</span>
						{monthlyPrice !== 0 && (
							<>
								<span className="font-size-xxxs">UPGRADE IN-APP</span>
							</>
						)}
					</a>
				</div>

				<div className="pl-4 pr-2 pt-4 pb-5 bg-light-green border-dark border-top border-bottom">
					<div className="font-size-xxxs">CAREER COACHING</div>
					{part1.map((item, index) => (
						<div key={index} className="font-size-medium pt-3">
							{item.indexOf("-nim-") >= 0 ? (
								<Image
									src={AskNimSVG}
									alt="First step"
									className=""
									responsive={false}
									width={65}
									height={13}
									style={{ paddingTop: 4 }}
								/>
							) : null}
							{item.replace("-nim-", "")}
						</div>
					))}
				</div>
				<div className="bg-gray pl-4 pr-2 pt-4 pb-5 border-dark border-bottom">
					<div className="font-size-xxxs">SKILLS & STRENGTHS</div>
					{part2.map((item, index) => (
						<div key={index} className="pt-3">
							{item}
						</div>
					))}
				</div>
				<div className="pl-4 pr-2 pt-4 pb-5">
					<div className="font-size-xxxs">LEARN & IMPROVE</div>
					{part3.map((item, index) => (
						<div className="pt-3" key={index}>
							{item}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
