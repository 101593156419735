import FullWidthSection from "../../../section/FullWidthSection";

const StoryUnfoldsSection = () => {
	return (
		<FullWidthSection
			bgColor="purple"
			className="py-11 py-md-20 d-flex justify-content-center align-items-center"
			containerClassName="ambitious-section row bg-white pt-7 pb-13 pt-md-15 pb-md-10 px-6 pl-0 pl-md-20"
		>
			<div className="col-12 col-md-6 text-center text-md-left">
				<h2 className="pb-4">It’s time to dictate the way your story unfolds</h2>
				<p className="font-size-lg m-0">
					Get started today with a free confidential 30 minute coaching session when you sign up, to see how we can help
					you.
				</p>
			</div>
			<div className="col-12 col-md-6 d-flex align-items-center justify-content-center pt-10 pt-md-0">
				<a className="btn btn-cornered btn-black btn-hover-light-green" href={process.env.GATSBY_GET_ACCESS_BUTTON}>
					Sign up
				</a>
			</div>
		</FullWidthSection>
	);
};

export default StoryUnfoldsSection;
