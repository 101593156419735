import Section from "../../../section/Section";

export default function FutureSection() {
	return (
		<Section className="future-section text-center pt-17 pt-md-15 pb-12 pb-md-15 mb-7 mb-md-15 border-bottom border-dark">
			<p className="m-0 font-size-lg text-underline pb-6">The future of work</p>
			<h2 className="mx-auto pb-8 pb-md-9" style={{ maxWidth: 498 }}>
				It’s time to dictate the way your story unfolds
			</h2>
			<div className="mx-auto mb-7 mb-md-15 border py-8 py-md-9 px-4 px-md-12 border-dark" style={{ maxWidth: 600 }}>
				<p className="m-0 font-size-lg pb-5">
					Our world has, and is, utterly transformed. The shape of work in our society is in another stage of evolution.
				</p>
				<p className="m-0 font-size-lg pb-5">
					We've recognised a disconnect between where work-life is headed and where people are; this is why Stay Nimble
					exists.
				</p>
				<p className="m-0 font-size-lg">
					We’re helping people in this state of change, who don’t know where to start, or how to start; those who
					previously were unable to access career support and development; or perhaps never thought it was an option.
				</p>
			</div>
			<a className="btn btn-cornered btn-black btn-hover-light-green" href={process.env.GATSBY_GET_ACCESS_BUTTON}>
				Sign up
			</a>
		</Section>
	);
}
