import Section from "../../../section/Section";
import StatsBox from "../../../v2/StatsBox";

export default function CareerCoachingSection() {
	return (
		<Section className="career-coaching-section text-center bg-purple">
			<p className="m-0 font-size-lg text-underline pb-6 pb-md-8">How it works</p>
			<h2 className="mx-auto h1" style={{ maxWidth: 824 }}>
				Career coaching that plugs into your life
			</h2>
		</Section>
	);
}
