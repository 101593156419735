import useAnonymousDataLayer from "../../../../../../src/_hooks/useAnonymousDataLayer";
import IndividualsHero from "./IndividualsHero";
import FeaturedIn from "../../../v2/featured-in/FeaturedIn";
import FutureSection from "./FutureSection";
import CareerCoachingSection from "./CareerCoachingSection";
import FirstStepSection from "./FirstStepSection";
import OnDemandSection from "./OnDemandSection";
import PersonaliseProgrammesSection from "./PersonaliseProgrammesSection";
import QualifiedSection from "../../../v2/QualifiedSection";
import Reviews from "../../../v2/Reviews";
import AwardWinning from "../../../v2/AwardWinning";
import AverageCoachRating from "../../../v2/AverageCoachRating";
import AffordableSection from "./AffordableSection";
import StoryUnfoldsSection from "./StoryUnfoldsSection";
import HomeLatestArticles from "../../../_pages/v2/home/HomeLatestArticles";
import MotionSection from "../../../v2/MotionSection";
import IndividualPricingPart2 from "./IndividualPricingPart2";
import IndividualPricingPart1 from "./IndividualPricingPart1";

const ForIndividualsDocument = () => {
	const { event } = useAnonymousDataLayer();

	return (
		<>
			<IndividualsHero />
			<FeaturedIn type="featured" title="Featured in" />
			<FutureSection />
			<CareerCoachingSection />
			<FirstStepSection />
			<OnDemandSection />
			<PersonaliseProgrammesSection />
			<QualifiedSection />
			<AwardWinning />
			<AverageCoachRating isConsultation={false} />
			<AffordableSection />
			<IndividualPricingPart1 />
			<IndividualPricingPart2 />
			<StoryUnfoldsSection />

			<Reviews
				readMore={true}
				className="pt-md-20 pt-10"
				title="Join the thousands of people…"
				subtitle="Rediscovering their ambitions and taking steps to happiness and contentment."
			/>
			<HomeLatestArticles title="Get inspired" />
			<MotionSection ctaType="url" />
		</>
	);
};

export default ForIndividualsDocument;
