import Section from "../../../section/Section";
import Image from "../../../Image";
import personalisedImage from "@images/v2/for-individiuals/personalised.png";

export default function PersonaliseProgrammesSection() {
	return (
		<Section className="personalised-programmes-section px-0">
			<div className="row mx-0 pt-13 pb-9 py-sm-14 justify-content-center justify-content-sm-start">
				<div className="col-12 col-sm-6 d-none d-sm-block pl-0">
					<Image src={personalisedImage} alt="Personalised" className="w-100" responsive={false} />
				</div>
				<div className="d-none d-sm-block col-sm-1"></div>
				<div className="col-12 col-sm-5 p-sm-0 text-center text-sm-left my-auto" style={{ maxWidth: 450 }}>
					<p className="font-size-lg text-underline pb-3 pb-sm-8 m-0">Personalised programmes</p>
					<h2 className="pb-5 pb-sm-10">Awaken your innate skills & talents</h2>
					<div className="d-block d-sm-none">
						<Image src={personalisedImage} alt="Personalised" className="w-100" responsive={false} />
					</div>

					<p className="font-size-xl m-0 pt-6 pt-sm-0" style={{ maxWidth: 441 }}>
						Everyone has their own edge. Your dedicated coach will craft an action plan with you, so you can act
						powerfully in your life and your work.
					</p>
					<a
						className="btn btn-cornered btn-black btn-hover-light-green mt-5 mt-sm-9"
						href={process.env.GATSBY_GET_ACCESS_BUTTON}
					>
						Sign up
					</a>
				</div>
			</div>
		</Section>
	);
}
