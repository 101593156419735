import Section from "../../../section/Section";
import Image from "../../../Image";
import firstStepImage from "@images/v2/for-individiuals/first-step.png";

export default function FirstStepSection() {
	return (
		<Section className="first-step-section">
			<div className="border-top border-dark mt-13 d-none d-sm-block w-100" />
			<div className="row mx-0 py-11 py-sm-10 justify-content-center justify-content-sm-between">
				<div className="col-12 col-sm-6 p-sm-0 text-center text-sm-left" style={{ maxWidth: 518 }}>
					<p className="font-size-lg text-underline pb-3 pb-sm-8 m-0">The first step</p>
					<h2 className="pb-5 pb-sm-10" style={{ maxWidth: 386 }}>
						Check-up on your Career health
					</h2>
					<div className="d-block d-sm-none">
						<Image src={firstStepImage} alt="First step" className="w-100" responsive={false} />
					</div>

					<p className="font-size-xl m-0 pt-6 pt-sm-0" style={{ maxWidth: 447 }}>
						First, we will discover a little more about you, your unique challenges, and your short to long-term goals
						with a free Discovery session when you sign up.
					</p>
					<a
						className="btn btn-cornered btn-black btn-hover-light-green mt-5 mt-sm-9"
						href={process.env.GATSBY_GET_ACCESS_BUTTON}
					>
						Sign up
					</a>
				</div>
				<div className="d-none d-sm-block col-sm-1"></div>
				<div className="col-12 col-sm-5 d-none d-sm-block">
					<Image src={firstStepImage} alt="First step" className="w-100" responsive={false} />
				</div>
			</div>
		</Section>
	);
}
